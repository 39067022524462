.step1-wrapper {
  .step1-title {
    font-size: 36px;
    line-height: 133%;
    letter-spacing: 0.2px;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin-bottom: 20px;
    margin: 20px 0;
    @media screen and (max-width:767px) {
      font-size: 24px;
    }
  }
  .step1-sub-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    color: rgb(159, 159, 159);
    margin-bottom: 1rem;
  }
  .step1-select {
    margin-bottom: 40px;
    .ant-select-selection-placeholder {
      text-align: left;
    }
    .step1-select-results {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      .skill-item {
        color: rgb(255, 255, 255);
        background: none 0% 0%/auto repeat scroll padding-box border-box rgb(149, 104, 255);;
        border-top: 2px solid rgba(255, 255, 255, 0.24);
        font-size: 18px;
        margin: 0px 20px 20px 0px;
        padding: 6px 15px;
        min-height: 30px;
        display: inline-flex;
        font-weight: 400;
        border-radius: 30px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0px 8px 15px 0px;
        transition: all 0.2s ease 0s;

        .skill-item-icon {
          min-width: 20px;
          border-left: 1px solid rgb(255, 255, 255);
          margin-left: 8px;
          padding-left: 6px;
          margin-right: -4px;
          line-height: 16px;
        }
      }
    }
  }
  .step1-body {
    .step1-body-title {
      text-align: left;
    }
    .popular-skills {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;

      .skill-item {
        color: rgb(0, 0, 0);
        background-color: transparent;
        border: 1px solid rgb(208, 206, 235);
        font-size: 18px;
        margin: 0px 20px 20px 0px;
        padding: 6px 15px;
        min-height: 30px;
        display: inline-flex;
        font-weight: 400;
        border-radius: 30px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0px 8px 15px 0px;
        transition: all 0.2s ease 0s;
      }
    }
    .step1-button-wapper {
      width: 100%;
      margin-top: 20px;
    }
  }
}
