.main-form {
  padding-top: 20px;
  height: 100%;
  margin: 0 auto;

  .main-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .back {
      cursor: pointer;
    }
    .process-percent {
      font-size: 16px;
      padding: 15px 20px;
      min-width: 140px;
      min-height: 30px;
      font-weight: 400;
      line-height: 120%;
      color: rgb(255, 255, 255);
      background-color: rgb(159, 159, 159);
      font-family: Poppins, sans-serif;
      border-radius: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
    }
  }
}
button.ant-btn-primary {
  background: none 0% 0%/auto repeat scroll padding-box border-box rgb(149, 104, 255);
        border-top: 2px solid rgba(255, 255, 255, 0.24);
        &:enabled:hover{
          background:radial-gradient(50% 87.5% at 50% 81.8%, rgb(196, 164, 255)  0%, rgb(149, 104, 255)  100%)
        }
}