img {
  max-width: 100%;
  height: auto;
}
.leader {
  display: flex;
  align-items: center;
  padding: 100px 0;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 50px 0;
    align-items: flex-start;
  }
  &-left {
    margin-right: 20px;
    font-size: 16px;
  }
  &-right {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      margin-top: 20px;
    }
  }
}
.features {
  text-align: center;
  padding-bottom: 100px;
  &-list {
    display: flex;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .box-icon {
      padding: 10px;
      &-icon {
        display: inline-flex;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: rgb(245, 237, 255);
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        svg {
          width: 24px;
          height: 24px;
          path {
            fill: rgb(149, 104, 255);
          }
        }
      }
      &-head {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 7px;
      }
      &-text {
        font-size: 12px;
        color: rgb(110, 108, 141);
      }
    }
  }
}
.hero {
  background-color: rgb(15, 14, 37);
  flex: 0 0 auto;
  height: 800px;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
  &-sub {
    display: inline-block;
    margin: auto;
    padding: 4px 8px;
    border-radius: 16px;
    background: rgb(240, 245, 255);
    color: rgb(87, 126, 255);
    font-size: 12px;
    font-weight: 600;
  }
  &-top {
    text-align: center;
    padding-bottom: 30px;
    h1 {
      font-size: 60px;
      font-weight: 500;
      letter-spacing: -0.04em;
      line-height: 72px;
      margin: 7px 0 10px;
    }
  }
  .available {
    marquee {
      color: rgb(135, 142, 168);
    }
  }
}

.discuss {
  background-color: rgb(149, 104, 255);
  border-radius: 16px;
  box-shadow: rgba(255, 255, 255, 0.2) 0.5625px 0.5625px 0.5625px 0px inset,
    rgba(0, 0, 0, 0.05) -0.5625px -0.5625px 0.5625px 0px inset;
  flex: 0 0 auto;
  height: 127px;
  overflow: hidden;
  position: relative;
  transform: perspective(1200px);
  width: 1080px;
  will-change: var(--framer-will-change-override, transform);
  margin: 100px 0;
  padding: 20px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  &-inner {
    background-image: url(../../assets/images/bg.svg);
    position: absolute;
    inset: -247px -8px -147px;
  }
  h6,
  p {
    color: #fff;
  }
  h6 {
    font-size: 26px;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 0;
  }
}
.quality-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  &-itm {
    width: calc(50% - 10px);
    margin: 10px 0;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  &-head {
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}
.sub-list {
  display: flex;
  border-top: 1px solid rgb(231, 232, 238);
  padding-top: 30px;
  justify-content: space-between;
  &-box {
    text-align: center;
    width: calc(50% - 10px);
    text-align: left;
    p {
      font-size: 12px;
      display: flex;
      align-items: center;
      svg{
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
  &-count {
    font-size: 38px;
    font-weight: 900;
  }
  &-head {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
  }
  &-icon {
    padding: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgb(245, 237, 255);
    margin-bottom: 10px;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: rgb(149, 104, 255);
      }
    }
  }
}
.question {
  &.testi {
    padding-bottom: 100px;
  }
  &.analytics,
  &.team,
  &.tech {
    padding-bottom: 100px;
    .question-left {
      width: 360px;
      flex: 1;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .question-right {
      width: 360px;
      box-sizing: border-box;
    }
  }
  &.team {
    flex-direction: row-reverse;
    align-items: stretch;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .question-right {
      border: 1px solid rgb(231, 232, 238);
      border-radius: 26px;
      background: rgb(246, 253, 248);
    }
    .team-inner {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
  &.tech {
    .question-right {
      background: rgb(246, 253, 248);
      padding: 60px 0;
      border: 1px solid rgb(231, 232, 238);
      border-radius: 24px;
      .tech-marquee {
        display: flex;
        width: 100%;
        .tech-box {
          margin-right: 16px;
        }
      }
    }
  }
}
.team-list {
  background-color: #fff;
  padding: 30px 0;
  &-marquee {
    padding: 10px 0;
    span {
      margin: 0 10px;
      transition: all 0.25s ease-in-out;
      &:hover {
        margin-top: -10px;
      }
    }
  }
  &-head {
    font-size: 18px;
    margin-left: 20px;
    padding-bottom: 20px;
  }
}
.tech-box {
  width: 204px;
  height: 232px;
  padding: 16px;
  background-color: #fff;
  text-align: center;
  border-radius: 16px;
  &-icon {
    margin-bottom: 20px;
    svg {
      width: 40px;
      height: 40px;
      path {
        fill: rgb(149, 104, 255);
      }
    }
  }
  &-head {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  &-text {
    display: flex;
    flex-wrap: wrap;
    span {
      display: block;
      width: 100%;
      background: rgb(245, 245, 252);
      border-radius: 16px;
      font-size: 12px;
      margin-top: 10px;
      &:nth-child(2),
      &:nth-child(3) {
        width: calc(50% - 10px);
      }
      &:nth-child(3) {
        margin-left: 20px;
      }
    }
  }
}
.testi-box {
  border: 1px solid rgb(231, 230, 245);
  padding: 16px;
  border-radius: 16px;
  &-head {
    color: rgb(149, 104, 255);
    font-weight: 600;
    margin-bottom: 10px;
  }
  + .testi-box {
    margin-top: 10px;
  }
}
.marquee-horizontal {
  display: inline-flex;
  justify-content: space-between;
  marquee {
    width: calc(50% - 5px);
    height: 340px;
  }
}
.box-how {
  margin: 0 0 100px;
  padding: 80px;
  background: rgb(15, 14, 37);
  text-align: center;
  color: #fff;
}
.timeline {
  display: flex;
  position: relative;
  padding: 100px 0;
  margin-top: 70px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    height: 760px;
  }

  &-tree {
    &-2,
    &-3,
    &-4,
    &-5,
    &-6 {
      position: absolute;
      font-size: 18px;
    }
    &-2,
    &-4,
    &-6 {
      top: -100px;
      @media screen and (max-width: 767px) {
        left: -20px !important;
      }
    }

    &-3,
    &-5 {
      bottom: -70px;
      @media screen and (max-width: 767px) {
        right: -20px !important;
        bottom: auto;
      }
    }
    &-2 {
      left: calc((100% / 6) - (100% / 6) / 2);
      @media screen and (max-width: 767px) {
        top: 76px;
      }
    }
    &-3 {
      left: calc((100% / 6) * 2 - (100% / 6) / 2);
      @media screen and (max-width: 767px) {
        top: 147px;
        left: auto;
      }
    }
    &-4 {
      left: calc((100% / 6) * 3 - (100% / 6) / 2);
      @media screen and (max-width: 767px) {
        top: 204px;
      }
    }
    &-5 {
      left: calc((100% / 6) * 4 - (100% / 6) / 2);
      @media screen and (max-width: 767px) {
        top: 265px;
      }
    }
    &-6 {
      left: calc((100% / 6) * 5 - (100% / 6) / 2);
      @media screen and (max-width: 767px) {
        top: 336px;
      }
    }
    &.svg {
      flex: 1;
      position: relative;
      @media screen and (max-width: 767px) {
        svg {
          transform: rotate(90deg);
          width: 400px;
          position: relative;
          left: -90px;
          bottom: -213px;
        }
      }
    }
    &.first,
    &.last {
      width: 200px;
      .timeline-tree-icon {
        position: relative;
        &::before,
        &::after {
          content: "";
          position: absolute;
        }
      }
    }
    &.first {
      .timeline-tree-icon {
        &::before,
        &::after {
          background: url(../../assets/images/file.svg) no-repeat center;
          background-size: cover;
          bottom: calc(100% + 20px);
        }
        &::before {
          width: 41px;
          height: 44px;
          transform: rotate(-30deg);
          left: 40px;
        }
        &::after {
          width: 49px;
          height: 54px;
          transform: rotate(30deg);
        }
      }
    }
    &.last {
      .timeline-tree-icon {
        &::before {
          background: url(../../assets/images/file.svg) no-repeat center;
          background-size: cover;
          bottom: calc(100% + 20px);
          width: 34px;
          height: 44px;
          left: 60px;
        }
        &::after {
          background: url(../../assets/images/start.svg) no-repeat center;
          background-size: cover;
          bottom: calc(100% + 40px);
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}
