html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  font-size: 14px;
  font-family: "Poppins", "Poppins Placeholder", sans-serif;
  color: #040415;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
p {
  margin: 0 0 16px;
}
.app-logo {
  position: relative;
  z-index: 100;
}
.App {
  overflow: hidden;
  background-color: #fff;
  &.home {
    position: relative;
    .App-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;
      background: transparent;
      .App-menu .ant-menu .ant-menu-item a {
        color: #fff;
      }
    }
  }
  &-header {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    position: relative;
    @media screen and (max-width: 767px) {
      padding: 0;
      justify-content: space-between;
    }
    &-inner {
      display: flex;
      align-items: center;
      padding: 40px 0 0;
    }
  }
  &-menu {
    width: 740px;
    margin-left: auto;
    @media screen and (max-width: 767px) {
      position: absolute;
      background: rgb(15, 14, 37);
      z-index: 99;
      width: 100%;
      padding: 80px 0 30px;
      top: 0;
      left: 0;
      display: none;
    }
    .ant-menu {
      display: flex;
      align-items: center;
      border-bottom: 0;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .ant-menu-item {
        line-height: 1;
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 500;
        @media screen and (max-width: 767px) {
          font-size: 36px;
          text-align: left;
          width: 100%;
        }
        a {
          color: rgb(46, 45, 65);
          @media screen and (max-width: 767px) {
            color: #fff;
            padding: 10px 0;
            display: block;
            &.btn {
              background: transparent;
              border-radius: 0;
              border-top: 1px solid rgba(110, 108, 141, 0.2);
              color: rgb(149, 104, 255) !important;
              padding-top: 30px;
              svg {
                display: none;
              }
            }
          }
        }
      }
    }
    .btn {
      padding: 8px 16px;
      color: #fff !important;
    }
  }
}
.hamburger {
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  margin-left: auto;
  z-index: 100;
  position: relative;
  @media screen and (min-width: 768px) {
    display: none;
  }
  &-box {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 14px;
  }
  &-inner {
    display: block;
    &::before,
    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 20px;
      height: 2px;
      border-radius: 2px;
      background-color: rgb(208, 206, 235);
    }
    &::before {
      top: 0;
      transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &::after {
      transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      bottom: 0;
    }
  }
}
.active {
  .App-menu {
    display: block;
  }
  .hamburger {
    &-inner {
      &:before {
        top: 0;
        transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s,
          transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
        transform: translate3d(0, 0px, 0) rotate(45deg);
      }
      &::after {
        top: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
        transform: translate3d(0, 0px, 0) rotate(-45deg);
      }
    }
  }
}
.heading {
  text-align: center;
  padding: 96px 0;
  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }
}
.h2 {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -0.04em;
  margin-bottom: 20px;
  @media screen and (max-width: 767px) {
    font-size: 24px;
    br {
      display: inline;
    }
  }
}
.App-link {
  color: #61dafb;
}
.container {
  width: 1120px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn {
  border-top: 2px solid rgba(255, 255, 255, 0.24);
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgb(149, 104, 255);
  border-radius: 32px;
  padding: 8px 16px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  &:hover {
    background: none 0% 0% / auto repeat scroll padding-box border-box
      rgb(149, 104, 255);
    border-top: 2px solid rgba(255, 255, 255, 0.24);
    &:enabled:hover {
      background: radial-gradient(
        50% 87.5% at 50% 81.8%,
        rgb(196, 164, 255) 0%,
        rgb(149, 104, 255) 100%
      );
    }
    color: #fff;
  }
}
.contact {
  padding: 0 0 90px;
  display: flex;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  &-left {
    width: 360px;
    margin-right: 40px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }
  }
  .main-form {
    flex: 1;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
.question {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 40px;
  height: min-content;
  justify-content: flex-start;
  overflow: visible;
  padding: 0;
  position: relative;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  &-left {
    width: 360px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  &-right {
    flex: 1;
    text-align: left;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .ant-collapse {
      border: 0;
    }
    .ant-collapse-item {
      margin-bottom: 10px;
      border: 1px solid rgb(208, 206, 235);
      border-radius: 16px !important;
      box-shadow: rgba(35, 44, 93, 0.06) 0px 12px 24px 0px;
      overflow: hidden;
    }
    .ant-collapse-header {
      flex-direction: row-reverse;
      background-color: #fff;
      font-weight: 500;
      color: rgb(46, 45, 65);
    }
    .ant-collapse-header-text {
      text-align: left;
    }
    .ant-collapse-content {
      border-top: 0;
      font-weight: 300;
      color: rgb(163, 161, 197);
    }
  }
}
.available {
  padding: 96px 0;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: 767px) {
    padding: 30px 0;
    marquee {
      display: none;
    }
  }
  &-left {
    white-space: nowrap;
    text-transform: uppercase;
    // -webkit-animation: moveleft 8s linear infinite; /* Safari 4.0 - 8.0 */
    white-space: nowrap;
    // animation: moveleft 8s linear infinite alternate;
    font-size: 24px;
    font-weight: 500;
    opacity: 0.4;
    -webkit-mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0) 16%,
      rgb(0, 0, 0) 84%,
      rgba(0, 0, 0, 0) 100%
    );
    overflow: hidden;
    padding: 10px 0;
  }
  &-right {
    white-space: nowrap;
    text-transform: uppercase;
    // -webkit-animation: mymove 8s linear infinite; /* Safari 4.0 - 8.0 */
    white-space: nowrap;
    // animation: mymove 8s linear infinite alternate;
    font-size: 24px;
    font-weight: 500;
    opacity: 0.4;
    -webkit-mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0) 16%,
      rgb(0, 0, 0) 84%,
      rgba(0, 0, 0, 0) 100%
    );
    overflow: hidden;
    padding: 10px 0;
  }
  &-text {
    width: 640px;
    padding: 64px 30px;
    box-sizing: border-box;
    margin: auto;
    background: #fff;
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    @media screen and (max-width: 767px) {
      position: relative;
      width: 100%;
      padding: 24px 30px;
      transform: none;
    }
  }
  &-avatar {
    display: inline-flex;
    align-items: center;
    span {
      color: rgb(208, 206, 235);
    }
  }
}
.subhead {
  padding: 4px 8px;
  border-radius: 16px;
  background-color: rgb(240, 245, 255);
  color: rgb(87, 126, 255);
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes moveleft {
  from {
    left: 0;
  }
  to {
    left: -140px;
  }
}

@keyframes moveleft {
  from {
    left: 0;
  }
  to {
    left: -140px;
  }
}
.group-btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .btn {
    & + .btn {
      margin-left: 24px;
    }
  }
}
.footer {
  align-content: center;
  align-items: center;
  background: radial-gradient(
    34.336561178217% 64.45783197334707% at 49.99999590676293% -1.4876988529977098e-12%,
    #202056 0%,
    rgba(4, 4, 21, 1) 100%
  );
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  height: min-content;
  justify-content: flex-start;
  overflow: visible;
  padding: 64px 0;
  position: relative;
  width: 100%;
}

footer {
  color: rgb(135, 142, 168);
}
.footer-top {
  display: flex;
  padding-bottom: 20px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.footer-nav {
  display: flex;
  justify-content: space-between;
  width: calc(50% - 20px);
  margin-left: 40px;
}
.footer-nav ul {
  list-style: none;
}
.footer-nav ul li {
  text-align: left;
}
.footer-nav ul li + li {
  margin-top: 15px;
}
.footer-nav ul li a {
  color: rgb(231, 232, 238);
  text-decoration: none;
  font-size: 12px;
}
.footer-logo {
  width: calc(50% - 20px);
  text-align: left;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.footer-bottom {
  border-top: 1px solid rgba(135, 142, 168, 0.2);
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
  .mail {
    color: rgb(231, 232, 238);
    display: inline-flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}
