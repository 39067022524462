.step4-wrapper {
  padding-top: 50px;

  .step4-title {
    font-size: 24px;
    line-height: 160%;
    letter-spacing: 0.2px;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin-bottom: 40px;
    margin: 20px 0;
  }
  .step4-sub-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    color: rgb(159, 159, 159);
    margin-bottom: 1rem;
  }
  .number-enginner {
    color: #6610f2;
    font-weight: 600;
  }
  .step4-body {
    margin: 0 auto;
    .custom-input {
      height: 45px;
      input {
        height: 40px;
        padding: 7px 11px;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 8px;
        border-style: solid;
        border-color: #d9d9d9;
        border-width: 1px;
        transition: all 0.2s;
        &:focus-within {
          border-color: #1677ff;
          box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
          outline: 0;
        }
      }
    }
  }
}
