.step2-wrapper {
  padding-top: 50px;

  .step2-title {
    font-size: 36px;
    line-height: 133%;
    letter-spacing: 0.2px;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin-bottom: 20px;
    margin: 20px 0;
  }
  .step2-sub-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    color: rgb(159, 159, 159);
    margin-bottom: 1rem;
  }
  .number-enginner {
    color: #6610f2;
    font-weight: 600;
  }
  .step2-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding-top: 20px;
  }
  .members-item {
    padding: 40px 32px 42px;
    gap: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.02) 0px 10px 20px;
    border-radius: 8px;
    justify-content: space-between;
    min-width: 120px;
    cursor: pointer;

    &:hover {
      background: rgb(238, 241, 252);
    }
  }
  .members-item.active {
    background: rgb(238, 241, 252);
  }
}
